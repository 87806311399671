<template>
  <div class="main_container">
    <div class="row mb-3 bread-crumbs">
      <div class="text-left col-12">
        <span>Rapports > Rapport des décomptes</span>
      </div>
    </div>
    <div class="row justify-content-center my-4">
      <div class="col-8">
        <div class="card title-card">
          <div class="card-body text-center title">
            Rapport des décomptes
          </div>
        </div>
      </div>
    </div>
    <div class="row mb-2">
      <!-- <span> <span class="text-info"> Analyses des charges  &rarr;  </span>  La repartition de ce montant par catégorie de charges</span>  -->
      <form class="form-inline mx-auto">
        <div class="form-group">
          <label for="">Période du: </label>
          <input class="form-control mx-2" 
                 name="" 
                 id=""
                 type="date"
                 v-model="annee_debut"/>
        </div>
        <div class="form-group">
          <label for="">au: </label>
          <input class="form-control mx-2" 
                 name="" 
                 id=""
                 type="date"
                 v-model="annee_fin"/>
        </div>
        <div class="form-group">
          <label for="">Statut des programmes: </label>
          <select class="form-control mx-2"
                  v-model="etat">
            <option value="">Choisissez un statut</option>
            <option value="actif">Actifs</option>
            <option value="inactif">Clôturés</option>
            <option value="tout">Tous</option>
          </select>        
        </div>
        <div class="form-group">
          <button type="button"
                  class="btn btn-info"
                  @click="changeYear()">
            Rechercher
          </button>
        </div>
      </form>
    </div>
    <div class="row">
      <table class="table table-blue"> 
        <thead>
          <tr>
            <!-- <th class="th-blue"></th> -->
            <th class="tht-dark-blue"
                scope="col"
                style="vertical-align: middle;border-right: 2px solid #dbdbdb">Programme</th>
            <th class="tht-dark-blue text-center"
                scope="col"
                style="border-right: 2px solid #dbdbdb">Date de démarrage</th>
            <th class="tht-dark-blue"
                scope="col"
                style="border-right: 2px solid #dbdbdb">Date de clôture</th>
            <th class="tht-dark-blue text-right"
                scope="col"
                style="vertical-align: middle;border-right: 2px solid #dbdbdb">Décomptes reçus (FCFA)</th>
            <th class="tht-dark-blue text-right"
                scope="col"
                style="vertical-align: middle;border-right: 2px solid #dbdbdb">Décomptes retournés (FCFA)</th>
            <th class="tht-dark-blue text-right"
                scope="col"
                style="border-right: 2px solid #dbdbdb">Décomptes payés (FCFA)</th>
            <th class="tht-dark-blue text-right"
                scope="col">Décomptes en attente de paiement (FCFA)</th>
          
          </tr>
        </thead>
        <tbody>
          <tr scope="row"
              v-for="(program,programKey) in decomptePer"
              :key="programKey">
            <!-- <td class="bg-info "
                id="first">  </td> -->
            <td class="text-left"
                style="border-right: 2px solid #dbdbdb">{{program.programme_libelle}}</td>
            <td style="border-right: 2px solid #dbdbdb">{{program.date_demarrage_programme}}</td>
            <td style="border-right: 2px solid #dbdbdb">{{program.date_cloture_programme}}</td>
            <td class="text-right"
                style="border-right: 2px solid #dbdbdb">{{Math.round(Number(program.decomptes_recus)).toLocaleString()}}</td>
            <td class="text-right"
                style="border-right: 2px solid #dbdbdb">{{Math.round(Number(program.decompte_retournes)).toLocaleString()}}</td>
            <td class="text-right"
                style="border-right: 2px solid #dbdbdb">{{Math.round(Number(program.total_paiement)).toLocaleString()}}</td>
            <td class="text-right">{{Math.round(Number(program.total_en_attente)).toLocaleString() }}</td>
          
          </tr>
        </tbody>
        <tfoot>
          <!-- Intergrer le total une fois disponible car on aura plus de pagination-->
          <tr scope="row"
              class="tht-blue">
            <!-- <td class="bg-info"></td> -->
            <td class="text-left text-uppercase"
                style="border-right: 2px solid #dbdbdb"
                colspan="3">Total</td>
            <td class="text-right"
                style="border-right: 2px solid #dbdbdb">{{Math.round(Number(totaux.decomptes_recus)).toLocaleString()}}</td>
            <td class="text-right"
                style="border-right: 2px solid #dbdbdb">{{Math.round(Number(totaux.decompte_retournes)).toLocaleString()}}</td>
            <td class="text-right"
                style="border-right: 2px solid #dbdbdb">{{Math.round(Number(totaux.total_paiement)).toLocaleString()}}</td>
            <td class="text-right">{{Math.round(Number(totaux.total_en_attente)).toLocaleString()}}</td>
          </tr>
        </tfoot>
      </table>
    </div>
    <div class="row mt-3">
      <div class="col-md-12">
        <div class="row">
          <h5 class="text-center">Décompte reçus</h5>
        </div>
        <div class="row">
          <div style="width: 100%;"
               v-if="decomptePer.length>0 ">
            <BarChart :chartdata="chartDataBarRecus"
                      :options="optionsChart1"/>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-md-4">
        <h5>
          Analyse:
        </h5>
        <p>
          Sur un montant total de {{Math.round(Number(totaux.decomptes_recus)).toLocaleString()}} Frs CFA de décomptes reçus et validés, 
          {{Math.round(Number(totaux.total_paiement)).toLocaleString()}} Frs CFA ont été payés, 
          soit 
          {{
            totaux.decomptes_recus>0?Math.round(Number(totaux.total_paiement)*100/totaux.decomptes_recus):0
          }}
          % d'exécution financière. 
          Il reste donc {{Math.round(Number(totaux.total_en_attente)).toLocaleString()}} Frs CFA, 
          soit
          {{
            totaux.decomptes_recus>0?Math.round(Number(totaux.decomptes_recus-totaux.total_paiement)*100/totaux.decomptes_recus):0
          }}
          % de décomptes en attente de paiement à la date du 
          {{annee_fin.split("-")[2]+"-"+annee_fin.split("-")[1]+"-"+annee_fin.split("-")[0]}}
        </p>
      </div>
      <div class="col-md-8">
        <div class="row">
          <div style="width: 100%;"
               v-if="decomptePer.length>0">
            <Pie :chartdata="chartDataPieChart"
                 :height="270"></Pie>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-md-4"></div>
      <div class="col-md-4"></div>
      <div class="col-md-4"></div>
    </div>
  </div>
</template>
<script>

import {mapActions,mapMutations, mapGetters} from "vuex"
import BarChart from "@/components/shared/BarChart"
import Pie from "@/components/shared/PieChart"
export default{
  name:"RapportDecompte",
  components:{
    // VueHtml2pdf,
    BarChart,
    Pie,
  },
  data:() =>({
    decomptePer:[],
    index:1,
    annee_debut: new Date().getFullYear()+"-01-01",
    annee_fin: new Date().getFullYear()+"-12-31",
    totaux:{
      decomptes_anterieurs:0,
      decomptes_anterieurs_en_attentes: 0,
      decomptes_recus: 0,
      decompte_retournes:0,
      decomptes_recus_en_attentes: 0,
      decomptes_recus_periode: 0,
      stock_anterieure_n: 0,
      stock_anterieure_n_1: 0,
      total_decomptes_a_payer: 0,
      total_en_attente: 0,
      total_paiement: 0,
    },
    etat:"actif",
    chartDataBarRecus: {
      labels: [],
      datasets: [
        {
          label: "Décomptes reçus",
          data: [],
          backgroundColor: ["blue"]
        },
        {
          label: "Décomptes retournés",
          data: [],
          backgroundColor: ["red"]
        },
        {
          label: "Décomptes en attente",
          data: [],
          backgroundColor: ["orange"]
        },
        {
          label: "Décomptes payés",
          data: [],
          backgroundColor: ["green"]
        },
      ]
    },
    chartDataPieChart: {
      labels: ["décomptes retournés","décomptes en attente de paiement","décomptes payés","reste à payer",],
      datasets: [
        {
          label: "Analyse des décomptes du programme",
          data: [],
          backgroundColor: ["#0097A9", "#016677", "#00B5CB", "#00383F", "#01606B"],
          hoverOffset: 4,
        },
      ],
    },
    optionsChart1: {
      responsive: true,
      maintainAspectRatio: false
    } 
  }),
  watch:{
    reportingDecompte(){
      if (this.reportingDecompte) {
        this.decomptePer = this.reportingDecompte.donnees
        this.totaux.decomptes_recus= 0
        this.totaux.decompte_retournes= 0
        this.totaux.total_en_attente= 0
        this.totaux.total_paiement= 0

        this.chartDataBarRecus = {
          labels: [],
          datasets: [
            {
              label: "Décomptes reçus",
              data: [],
              backgroundColor: ["blue"]
            },
            {
              label: "Décomptes retournés",
              data: [],
              backgroundColor: ["red"]
            },
            {
              label: "Décomptes en attente",
              data: [],
              backgroundColor: ["orange"]
            },
            {
              label: "Décomptes payés",
              data: [],
              backgroundColor: ["green"]
            },
          ]
        }
        this.chartDataPieChart = {
          labels: ["décomptes retournés","décomptes en attente de paiement","décomptes payés","reste à payer",],
          datasets: [
            {
              label: "Analyse des décomptes des programmes",
              data: [],
              backgroundColor: ["#0097A9", "#016677", "#00B5CB", "#00383F", "#01606B"],
              hoverOffset: 4,
            },
          ],
        }

        this.decomptePer.forEach(per => {
          this.totaux.decomptes_recus += Number(per.decomptes_recus)
          this.totaux.decompte_retournes += Number(per.decompte_retournes)
          this.totaux.total_en_attente += Number(per.total_en_attente)
          this.totaux.total_paiement += Number(per.total_paiement)
          // remplissage du graphe des décomptes recus
          this.chartDataBarRecus.labels.push(per.programme_libelle)
          this.chartDataBarRecus.datasets[0].data.push(Number(per.decomptes_recus))
          this.chartDataBarRecus.datasets[1].data.push(Number(per.decompte_retournes))
          this.chartDataBarRecus.datasets[2].data.push(Number(per.total_en_attente))
          this.chartDataBarRecus.datasets[3].data.push(Number(per.total_paiement))
        })
        this.chartDataPieChart.datasets[0].data.push(
          this.totaux.decomptes_recus>0
            ?Math.round(((this.totaux.decompte_retournes*100)/this.totaux.decomptes_recus)*100):0
        )
        this.chartDataPieChart.datasets[0].data.push(
          this.totaux.decomptes_recus>0
            ?Math.round(((this.totaux.total_en_attente*100)/this.totaux.decomptes_recus)*100):0
        )
        this.chartDataPieChart.datasets[0].data.push(
          this.totaux.decomptes_recus>0
            ?Math.round(((this.totaux.total_paiement*100)/this.totaux.decomptes_recus)*100):0
        )
        this.chartDataPieChart.datasets[0].data.push(
          this.totaux.decomptes_recus>0
            ?Math.round(((this.totaux.decomptes_recus-this.totaux.total_paiement*100)/this.totaux.decomptes_recus)*100):0
        )
        console.log(this.chartDataPieChart)
      }
    },
  },
  created() {
    this.getReportingDecompte({annee_debut:this.annee_debut,annee_fin:this.annee_fin,etat:this.etat})
  },
  computed:{
    ...mapGetters(["reportingDecompte"])
  },
  methods:{
    ...mapActions(["getReportingDecompte"]),
    ...mapMutations(["setReportingDecompte"]),
    changeYear(){
      this.totaux={
        decomptes_anterieurs:0,
        decomptes_anterieurs_en_attentes: 0,
        decomptes_recus: 0,
        decompte_retournes:0,
        decomptes_recus_en_attentes: 0,
        decomptes_recus_periode: 0,
        stock_anterieure_n: 0,
        stock_anterieure_n_1: 0,
        total_decomptes_a_payer: 0,
        total_en_attente: 0,
        total_paiement: 0,
      }
      this.decomptePer=[]
      this.setReportingDecompte("")
      this.getReportingDecompte({annee_debut:this.annee_debut,annee_fin:this.annee_fin,etat:this.etat})
    },
  }
}
</script>